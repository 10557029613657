<template>
  <div class="row mx-auto">
    <div class="col-12 game_logo_img fw-bolder my-3">
      {{ $t("text_game_share").toUpperCase() }}
    </div>
    <div class="row mx-auto">
      <p class="mt-3 mb-2">
        {{ $t("home_text6").toUpperCase() }}
      </p>
      <div
        class="col-6 col-xl-3"
        style="padding: 5px"
        v-for="(l, k) in casino_list"
        :key="k"
        @click="click_detail(l)"
      >
        <div class="card border-0" style="border-radius: 26px">
          <div class="card-images">
            <img
              class="card-img-top position-relative rounded-3"
              v-lazy="l.img"
            />
            <div class="mask position-absolute top-0 start-0"></div>
            <div
              class="card-body p-xl-3 d-flex justify-content-around flex-wrap ps-1 pe-0 align-items-center"
            >
              <h6 class="text-truncate mb-1 text-invest">
                {{ $t("invest_text3") }}：
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                />
                {{ l.payment }}
              </h6>
              <h6 class="text-truncate mb-1 text-invest">
                {{ $t("invest_text13") }}：
                {{ parseFloat((l.rate * 100).toFixed(2)) }}%
              </h6>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-3 mb-2">
        {{ $t("invest_text14").toUpperCase() }}
      </p>
      <div
        class="col-6 col-xl-3"
        style="padding: 5px"
        v-for="(l, k) in slot_list"
        :key="k"
        @click="click_detail(l)"
      >
        <div class="card border-0" style="border-radius: 26px">
          <div class="card-images">
            <img
              class="card-img-top position-relative rounded-3"
              v-lazy="l.img"
            />
            <div class="mask position-absolute top-0 start-0"></div>
            <div
              class="card-body p-xl-3 d-flex justify-content-around flex-wrap ps-1 pe-0 align-items-center"
            >
              <h6 class="text-truncate mb-1 text-invest">
                {{ $t("invest_text3") }}：
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                />
                {{ l.payment }}
              </h6>
              <h6 class="text-truncate mb-1 text-invest">
                {{ $t("invest_text13") }}：
                {{ parseFloat((l.rate * 100).toFixed(2)) }}%
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="w-25 mx-auto text-center w-lg-75 mt-3"
      v-if="list_index != list.length"
    >
      <button class="w-100 btn btn-success" @click="more()">
        <a>{{ $t("slot_text2") }}</a>
      </button>
    </div> -->

    <div class="finance_page" v-show="flag_finance_page">
      <div class="finance_card">
        <div class="finance_close" @click="flag_finance_page = false">×</div>
        <div class="finance_title">
          {{ $t("invest_text1") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 10px">
            <p>{{ $t("invest_text2") }}：{{ cou_name }}</p>
            <p>
              {{ $t("invest_text3") }}：
              <img
                src="@/assets/img/main/diamond.png"
                style="width: 25px; height: 25px"
                class="me-1"
              />{{ cou_price }}
            </p>
            <p>
              {{ $t("invest_text4") }}：
              {{ parseFloat((cou_rate * 100).toFixed(2)) }}%
            </p>

            <div>
              <p class="mb-2">
                {{ $t("invest_text5") }}
              </p>
              <div class="position-relative d-flex align-items-center">
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="mx-2 position-absolute"
                />
                <input
                  type="text"
                  class="finance_text text-center"
                  v-model="payment"
                />
              </div>
              <p v-show="flag_pay_error">
                <span style="color: Red">{{ $t("error_tips") }}</span>
              </p>
            </div>
            <div class="col-10 text-white mx-auto my-3">
              <!-- @click="buy_sharebonus_item" -->
              <button
                class="border-0 text-white bg_green_btn p-2 w-100"
                :disabled="flag_pay_error"
                :style="
                  flag_pay_error
                    ? 'cursor: no-drop;background-color: #CCC;'
                    : ''
                "
              >
                <span>{{ $t("invest_text6") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
export default {
  props: {},
  data() {
    return {
      casino_list: [],
      slot_list: [],
      flag_finance_page: false,
      flag_pay_error: false,
      payment: 0,
      cou_name: "",
      cou_price: "",
      cou_rate: "",
      cou_mpkidx: "",
      list_index: 6,
    };
  },
  methods: {
    sharebonus_item: function () {
      let param = {
        action: "sharebonus_item",
        body: {
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.slot_list = res.data[0]['slot'];
          this.casino_list = res.data[1]['casino'];
        }
      });
    },
    click_detail: function (e) {
      this.flag_finance_page = true;
      this.cou_name = e.cname;
      this.cou_price = e.payment;
      this.cou_rate = e.rate;
      this.cou_mpkidx = e.mpkidx;
    },
    buy_sharebonus_item: function () {
      if (this.payment != 0 && this.payment != "" && this.payment != null) {
        let param = {
          action: "sharebonus_buy",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            mpkidx: this.cou_mpkidx,
            finance: this.payment,
            withdrawpw: "",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.$MSG.API_popmsg(res.msg, "", "success");
          }
        });
      } else this.flag_pay_error = true;
    },
    more: function () {
      this.list_index = this.list.length;
    },
  },
  mounted() {},
  created() {
    this.sharebonus_item();
  },
  watch: {
    payment: function (e) {
      if (!Number(e)) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
    },
  },
};
</script>
