import { createI18n } from "vue-i18n";
import en from "./en-US.json";
var locale = localStorage.getItem("locale") ?? process.env.VUE_APP_LANG;

sessionStorage.setItem("Json", JSON.stringify(en));
if (localStorage.getItem("locale") == null) {
  localStorage.setItem("locale", process.env.VUE_APP_LANG);
}

const i18n = createI18n({
  legacy: false,
  locale: locale,
  fallbackLocale: process.env.VUE_APP_LANG,
  messages: {
    en: en,
  },
  // globalInjection: true
});

export default i18n;
