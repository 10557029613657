<template>
  <div
    id="language"
    style="display: block"
    @click="this.$store.commit('Lang', false)"
    v-if="islang"
  >
    <div class="language_card">
      <div
        style="
          width: 100%;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-weight: bold;
        "
      >
        {{ $t("selectLang") }}
      </div>
      <div class="word" @click="lang('vi')">Tiếng Việt</div>
      <div class="word" @click="lang('en')">English</div>
      <div class="word" @click="lang('cn')">中文</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      locale: localStorage.getItem("locale"),
      islang: false,
    };
  },
  computed: {},
  watch: {
    locale: function (e) {
      localStorage.setItem("locale", e);
      window.location.reload();
    },
  },
  methods: {
    lang: function (e) {
      this.locale = e;
    },
  },
};
</script>
