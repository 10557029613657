<template>
  <div id="statistics">
    <div class="mt-xl-3 mt-1 border_eee box_shadow p-2">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="Agent_statistics__u7ohA">
            <div class="Agent_datePicker__pmxhq border_eee box_shadow p-2">
              <div class="Agent_segmented__Gkuak">
                <div class="ant-segmented ant-segmented-lg">
                  <div class="ant-segmented-group">
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 1 ? 'selected' : ''"
                      @click="this.day = 1"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("text_day") }}
                      </div>
                    </label>
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 7 ? 'selected' : ''"
                      @click="this.day = 7"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("com_week") }}
                      </div>
                    </label>
                    <label
                      class="ant-segmented-item rounded-5"
                      :class="this.day == 30 ? 'selected' : ''"
                      @click="this.day = 30"
                    >
                      <div class="ant-segmented-item-label">
                        {{ $t("text_month") }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="text-xl-end">
                <div
                  class="ant-picker ant-picker-range ant-picker-large d-inline-block w-xl-75 text-xl-start"
                >
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("start_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="begindate"
                      @change="begindate_change"
                    />
                  </div>
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("end_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="enddate"
                      @change="enddate_change"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div style="margin: 20px auto">
              <div
                class="Agent_tableBlock__kIjCN GbTable_root__aRhLC"
                style="border-radius: 10px"
              >
                <div class="Agent_statisticItemHeader__JZgtK">
                  <span class="text_gray"> {{ $t("comm_text1") }} </span>
                </div>
                <table
                  style="table-layout: auto"
                  class="w-100 GbTable_table__moqvH"
                >
                  <thead class="ant-table-thead">
                    <tr>
                      <th>{{ $t("comm_text2") }}</th>
                      <th>{{ $t("comm_text3") }}</th>
                      <th>{{ $t("comm_text4") }}</th>
                      <th>{{ $t("comm_text5") }}</th>
                      <th>{{ $t("comm_text6") }}</th>
                    </tr>
                  </thead>
                  <tbody class="ant-table-tbody">
                    <tr class="ant-table-row ant-table-row-level-0">
                      <td>{{ validmen }}</td>
                      <td>
                        {{ Math.round(losewin * ratio) / 100 }}({{ losewin }})
                      </td>
                      <td>
                        {{ Math.round((game + fee + promotion) * ratio) / 100 }}
                      </td>
                      <td>{{ ratio }}%</td>
                      <td>{{ payment }}</td>
                    </tr>
                  </tbody>
                </table>
                <div class="Agent_statisticItemHeader__JZgtK">
                  <span class="text_gray"> {{ $t("comm_text4") }} </span>
                </div>
                <table
                  style="table-layout: auto"
                  class="w-100 GbTable_table__moqvH"
                >
                  <thead class="ant-table-thead">
                    <tr>
                      <th>{{ $t("comm_text7") }}</th>
                      <th>{{ $t("comm_text8") }}</th>
                      <th>{{ $t("comm_text9") }}</th>
                      <th>{{ $t("comm_text10") }}</th>
                    </tr>
                  </thead>
                  <tbody class="ant-table-tbody">
                    <tr class="ant-table-row ant-table-row-level-0">
                      <td>
                        {{ Math.round(game * ratio) / 100 }}({{ game_ori }})
                      </td>
                      <td>
                        {{ Math.round(fee * ratio) / 100 }}({{ fee_ori }})
                      </td>
                      <td>
                        {{ Math.round(promotion * ratio) / 100 }}({{
                          promotion_ori
                        }})
                      </td>
                      <td>
                        {{ Math.round((game + fee + promotion) * ratio) / 100 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="Agent_tableBlockM__-tsPI border_eee box_shadow p-2">
                <div class="Agent_statisticItem__9+dXQ">
                  <div class="Agent_statisticItemHeader__JZgtK">
                    <span class="text_gray"> {{ $t("comm_text1") }} </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #f5f5f5"
                  >
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text2") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text3") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text4") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text5") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text6") }}
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #fff"
                  >
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ validmen }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round(losewin * ratio) / 100 }}({{ losewin }})
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round((game + fee + promotion) * ratio) / 100 }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ ratio }}%
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ payment }}
                    </span>
                  </div>
                  <div class="Agent_statisticItemHeader__JZgtK">
                    <span class="text_gray"> {{ $t("comm_text4") }} </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #f5f5f5"
                  >
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text7") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text8") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text9") }}
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ $t("comm_text10") }}
                    </span>
                  </div>
                  <div
                    class="Agent_statisticItemRow__v3Gi0"
                    style="background-color: #fff"
                  >
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round(game * ratio) / 100 }}({{ game_ori }})
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round(fee * ratio) / 100 }}({{ fee_ori }})
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round(promotion * ratio) / 100 }}({{
                        promotion_ori
                      }})
                    </span>
                    <span class="Agent_statisticItemRowItem__7i9px">
                      {{ Math.round((game + fee + promotion) * ratio) / 100 }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "statistics",
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      begin: dateTime.getToday(),
      end: dateTime.getToday(),
      day: 1,
      validmen: 0,
      losewin: 0,
      ratio: 0,
      game: 0,
      fee: 0,
      game_ori: 0,
      fee_ori: 0,
      promotion_ori: 0,
      promotion: 0,
      payment: 0,
    };
  },
  watch: {
    day: function (e) {
      if (e == 1) {
        this.begin = dateTime.getToday();
      } else if (e == 30) {
        var d =
          dateTime.getYear() +
          "-" +
          (dateTime.getMonth() - 1) +
          "-" +
          dateTime.getDate();
        this.begin = d;
      } else {
        this.begin = dateTime.getoneday(-e);
      }
      if (this.code != "") {
        this.fourth();
      }
    },
  },
  methods: {
    fourth: function () {
      let param = {
        action: "fourth",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.validmen = res["validmen"];
          this.losewin = res["losewin"];
          this.ratio = res["ratio"];
          this.game = res["game"];
          this.fee = res["fee"];
          this.game_ori = res["game_ori"];
          this.fee_ori = res["fee_ori"];
          this.promotion_ori = res["promotion_ori"];
          this.promotion = res["promotion"];
          this.payment = res["payment"];
        }
      });
    },
    begindate_change: function () {
      this.begin = document.getElementById("begindate").value;
      if (this.code != "") {
        this.fourth();
      }
    },
    enddate_change: function () {
      this.end = document.getElementById("enddate").value;
      if (this.code != "") {
        this.fourth();
      }
    },
  },
  created() {
    if (this.code != "") {
      this.fourth();
    }
  },
};
</script>
