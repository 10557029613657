<template>
  <div class="my-5">
    <div class="d-flex justify-content-end">
      <img src="@/assets/img/main/dwrank.gif" style="width: 100px" />
    </div>
    <div class="d-lg-flex justify-content-between text-white">
      <div
        class="me-lg-2 text-center p-2 rounded-3 mt-2"
        style="flex-grow: 1; background-color: #00bc86"
      >
        <span class="fw-bold" style="font-size: 18px">{{ $t("daili_rank_text1") }}</span>
        <div class="mt-3" style="border-radius: 16px">
          <div class="div-header header_dw">
            <span>{{ $t("home_text4") }}</span>
            <span>{{ $t("transaction_Amount") }}</span>
          </div>
          <div
            class="div-container"
            style="
              height: 45vh;
              overflow-y: hidden;
              border-bottom: 15px solid #00bc86;
              border-radius: 16px;
            "
          >
            <div class="content1 content1_dw" v-for="l in tableData" :key="l">
              <span>{{ l.userid }}</span>
              <span>
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="me-1"
                />{{ l.win }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="ms-lg-2 text-center p-2 rounded-3 mt-2"
        style="flex-grow: 1; background-color: #faa41e"
      >
        <span class="fw-bold" style="font-size: 18px">{{
          $t("daili_rank_text2")
        }}</span>
        <div class="mt-3" style="border-radius: 16px">
          <div class="div-header header_dw">
            <span>{{ $t("home_text4") }}</span>
            <span>{{ $t("transaction_Amount") }}</span>
          </div>
          <div
            class="div-container"
            style="
              height: 45vh;
              overflow-y: hidden;
              border-bottom: 15px solid #faa41e;
              border-radius: 16px;
            "
          >
            <div class="content1 content1_dw" v-for="l in tableData_w" :key="l">
              <span>{{ l.userid }}</span>
              <span>
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="me-1"
                />{{ l.win }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      tableData: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
      ],
      tableData_w: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
      ],
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var insert_data = [
          {
            userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
            win: Math.ceil(Math.random() * 99899 + 10),
          },
        ];
        this.tableData.push(insert_data[0]);
        this.tableData.shift();
      }, 1000);
    },
    scroll_w() {
      setInterval(() => {
        var insert_data = [
          {
            userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
            win: Math.ceil(Math.random() * 99899 + 10),
          },
        ];
        this.tableData_w.push(insert_data[0]);
        this.tableData_w.shift();
      }, 1000);
    },
  },
  mounted() {},
  created() {
    this.scroll();
    this.scroll_w();
  },
  components: {},
};
</script>
